
    const req = require.context('./product', true);
    const ProductList = new Map();
    req.keys().forEach((eachPng) => {
        const imgConfig = req(eachPng);
        const imgName = eachPng.replace(/^\.\/(.*)\.\w+$/, '$1');
        ProductList.set(imgName, { imgName, picUrl: req(eachPng).default || imgConfig });
    });


export default ProductList
