
    const req = require.context('./my', true);
    const pngHashMap = new Map();
    req.keys().forEach((eachPng) => {
        const imgConfig = req(eachPng);
        const imgName = eachPng.replace(/^\.\/(.*)\.\w+$/, '$1');
        pngHashMap.set(imgName, { imgName, picUrl: req(eachPng).default || imgConfig });
    });


export default pngHashMap
