const pic10001 = require('@/assets/product/10001.jpg');
const pic10002 = require('@/assets/product/10002.jpg');
const pic10003 = require('@/assets/product/10003.jpg');
const pic10004 = require('@/assets/product/10004.jpg');
export const ProductDatas = [
    {
        picUrl: pic10001,
        name:'熊胆滴眼液',
        spec:'10ml',
        org:'云南傣药有限公司',
        unit:'瓶'
    },{
        picUrl: pic10002,
        name:'天麻头痛片',
        spec:'0.3克*15片*2板',
        org:'黑龙江乌苏里江制药有限公司哈尔滨分公司',
        unit:'盒'
    },{
        picUrl: pic10003,
        name:'臂式电子血压计(佳音型)（乐普）',
        spec:'U801',
        org:'深圳乐普智能医疗器械有限公司',
        unit:'台'
    },{
        picUrl: pic10004,
        name:'肝肾康糖浆',
        spec:'100ml',
        org:'李时珍医药集团有限公司',
        unit:'盒'
    }
]
