const logoPicUrl = require('@/assets/logo.jpg');
export const StoreDatas = [{
    AreaName:'总部',
    Children:[
        {
            picUrl: logoPicUrl,
            name:'惠州市万顺药业有限公司',
            addr:'惠州市惠阳区淡水承修四路67号三楼',
            tel:'0752-3361889'
        }
    ]
},{
    AreaName:'惠阳区域',
    Children:[
        {
            picUrl: logoPicUrl,
            name: '土湖分店',
            addr: '惠阳区淡水承修四路67号',
            tel: '0752-3361698'
        },{
            picUrl: logoPicUrl,
            name: '万顺路分店',
            addr: '淡水中山二路中顺公寓A幢1层04号铺',
            tel: '0752-3762589'
        },{
            picUrl: logoPicUrl,
            name: '中铭豪园分店',
            addr: '淡水市政广场东路中铭豪园18幢1层50号铺',
            tel: ' 0752-3338223'
        },{
            picUrl: logoPicUrl,
            name: '富景花园分店',
            addr: '惠阳区淡水南门西街46-6号一层',
            tel: ' 0752-3802663'
        },{
            picUrl: logoPicUrl,
            name: '长安南路分店',
            addr: '惠阳区淡水街道长安南街50号',
            tel: ' 0752-3355286'
        },{
            picUrl: logoPicUrl,
            name: '河背二分店',
            addr: '惠州市惠阳区淡水街道河背街111号',
            tel: ' 0752-3369521'
        },{
            picUrl: logoPicUrl,
            name:'中山路分店',
            addr:'惠阳区淡水中山三路65号',
            tel:'0752-3878133'
        },{
            picUrl: logoPicUrl,
            name:'金惠分店',
            addr:'惠阳区淡水金惠大道与东华道交界处',
            tel:'0752-3396548'
        },{
            picUrl: logoPicUrl,
            name:'承修路分店',
            addr:'惠阳区淡水承修四路2-2号',
            tel:'0752-3360335'
        },{
            picUrl: logoPicUrl,
            name:'开城分店',
            addr:'惠阳区淡水开城大道北18号',
            tel:'0752-3803615'
        },{
            picUrl: logoPicUrl,
            name:'河背分店', 
            addr:'惠阳区淡水镇河背街24号',
            tel:'0752-3358500'
        },{
            picUrl: logoPicUrl,
            name:'花街分店',
            addr:'惠阳区淡水镇上塘西街13号',
            tel:'0752-3399101'
        },{
            picUrl: logoPicUrl,
            name:'嘉华分店', 
            addr:'惠阳区淡水石坑四路',
            tel:'0752-3379682'
        },{
            picUrl: logoPicUrl,
            name:'长安路分店', 
            addr:'惠阳区长安南路193号宝贝仔母婴店隔壁',
            tel:'0752-3886869'
        },{
            picUrl: logoPicUrl,
            name:'中山四路分店',
            addr:'惠州市惠阳区淡水中山四路8号101铺位',
            tel:'0752-3831489'
        },{
            picUrl: logoPicUrl,
            name:'福利路分店',
            addr:'惠阳区淡水福利路11号(众德商务大厦)',
            tel:'0752-3832183'
        },{
            picUrl: logoPicUrl,
            name:'白石分店',
            addr:'惠阳区秋长镇白石源昌百货一楼',
            tel:'0752-3646983'
        },{
            picUrl: logoPicUrl,
            name:'棕榈园分店',
            addr:'惠阳区淡水土湖地段建曙棕榈园1层044号',
            tel:'0752-3332003'
        },{
            picUrl: logoPicUrl,
            name:'白云二路分店',
            addr:'惠阳区淡水白云二路200号',
            tel:'0752-3383443'
        },{
            picUrl: logoPicUrl,
            name:'长安北路分店',
            addr:'惠阳区淡水长安北路79号一层',
            tel:'13553402998'
        },{
            picUrl: logoPicUrl,
            name:'大埔分店',
            addr:'惠阳区淡水大埔叶挺东路116号',
            tel:'0752-3369027'
        },{
            picUrl: logoPicUrl,
            name:'星河丹堤分店',
            addr:'淡水星河丹堤花园E区2号B1层21号铺',
            tel:'0752-3381665'
        }
    ]
},{
    AreaName:'大亚湾区域',
    Children:[
        {
            picUrl: logoPicUrl,
            name:'吉祥分店',
            addr:'惠州市大亚湾西区上杨146号',
            tel:'0752-5561186'
        },{
            picUrl: logoPicUrl,
            name:'东联分店',
            addr:'大亚湾西区东联市场（发到家超市一楼）',
            tel:'0752-5559001'
        },{
            picUrl: logoPicUrl,
            name:'小径湾分店',
            addr:'大亚湾霞涌小径海岸城花园25栋1层06号',
            tel:'0752-8268983'
        }
    ]
},{
    AreaName:'惠东区域',
    Children:[
        {
            picUrl: logoPicUrl,
            name:'银滩分店',
            addr:'惠东县稔山镇碧桂园爱琴海13号楼3单元',
            tel:'0752-8738920'
        },{
            picUrl: logoPicUrl,
            name:'下园分店',
            addr:'惠东县稔山镇盐灶背沙子布下园',
            tel:'13928301483'
        },{
            picUrl: logoPicUrl,
            name:'银滩二分店',
            addr:'惠东县稔山镇碧桂园十里银滩山林海3号',
            tel:'0752-8268983'
        },{
            picUrl: logoPicUrl,
            name:'海悦湾分店',
            addr:'惠东县稔山镇盐灶背碧桂园海悦湾30号楼',
            tel:'0752-8119520'
        },{
            picUrl: logoPicUrl,
            name:'海悦湾分店',
            addr:'惠东县稔山镇亚婆角地段海头埔苑3号楼',
            tel:'0752-8119520'
        }
    ]
}
]